import {
  NEXT_STEP,
  PREV_STEP,
  SET_ACTIVE_STEP,
  FORM_SUBMITTED_STATE,
} from "../actions/navigation";

const initialState = {
  activeStepIndex: 0,
  submitted: false,
};

export default function navigation(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStepIndex: action.index,
      };

    case NEXT_STEP:
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
      };

    case PREV_STEP:
      if (state.activeStepIndex === 1) return state;
      return {
        ...state,
        activeStepIndex: state.activeStepIndex - 1,
      };

    case FORM_SUBMITTED_STATE:
      return {
        ...state,
        submitted: true,
      };

    default:
      return state;
  }
}
