import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './styles.css';

class Wrapper extends PureComponent {
  static handleKeyDown() {

  }

  constructor(props) {
    super(props);
    this.state = { opened: true };
    this.html = document.documentElement;
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.html.classList.add('noScroll');
  }

  closeModal(e) {
    if ([...e.target.classList].indexOf('closemodal') === -1) return;
    this.setState({ opened: false });
    this.html.classList.remove('noScroll');
    ReactDOM.unmountComponentAtNode(this.node.parentNode);
  }

  render() {
    const { children } = this.props;

    if (!this.state.opened) return null;

    return (
      <div
        className={`${styles.modalWrap} closemodal`}
        onClick={this.closeModal}
        onKeyDown={Wrapper.handleKeyDown}
        role="presentation"
        ref={(node) => { this.node = node; }}
      >
        {children}
      </div>
    );
  }
}

Wrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Wrapper;
