export const SERVICE_BOOKING_STEPS = [
  {
    heading: "Ваш Автомобіль",
    value: "Параметри Вашого автомобіля",
    type: "Auto",
    isRequired: true,
    hidden: false,
  },
  {
    heading: "Розрахунок Вартості",
    value: "Оберiть сервісні роботи",
    type: "Calc",
    isRequired: false,
    hidden: false,
  },
];
export const VEHICLE_OPTIONS = [
  {
    value: "conditioner",
    alias: "кондиціонер",
  },
  {
    value: "abs",
    alias: "ABS",
  },
  {
    value: "pas",
    alias: "Підсилювач керма",
  },
];
export const STEP_TOOLTIPS = {
  cancel:
    "После отмены Ваши данные будут удалены. Для создания новой заявки воспользуйтесь сервисом записи.",
  confirm:
    "Інформацію про запис на сервіс відправлено Вам на електронну пошту.",
  step1: "", //"Для вашого автомобіля доступні обов'язкові сервісні роботи",
  step2:
    "Дилеры, предлагающие как техническое обслуживание, так и кузовной ремонт",
  step5:
    "Укажите ваш email, чтобы получить подтверждение записи и ссылку для ее отмены. Вы сможете также отменить запись, позвонив дилеру.",
};
export const REQUEST_TIMEOUT = 150000;
