import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.css';

function empty() { return false; }

class Button extends PureComponent {
  render() {
    const {
      children,
      type,
      onClick,
      disabled,
      grey,
      classes,
      link,
      to,
    } = this.props;
    const btnClasses = classNames(
      classes,
      [styles.button],
      {
        [styles.buttonNext]: type === 'next' || type === 'final',
        [styles.buttonPrev]: type === 'prev',
        [styles.buttonDisabled]: disabled,
        [styles.buttonSignUp]: type === 'final',
        [styles.buttonRequest]: type === 'request',
        [styles.buttonConfirmCancel]: type === 'confirmCancel',
        [styles.buttonCancel]: type === 'cancel',
        [styles.buttonGrey]: grey,
      },
    );

    if (link) {
      return <a href={to} className={btnClasses} onClick={onClick}>{children}</a>;
    }

    return (
      <button className={btnClasses} onClick={onClick}>{children}</button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  grey: PropTypes.bool,
  classes: PropTypes.string,
  link: PropTypes.bool,
  to: PropTypes.string,
};

Button.defaultProps = {
  onClick: empty,
  children: '',
  disabled: false,
  type: '',
  grey: false,
  classes: '',
  link: false,
  to: '',
};

export default Button;
