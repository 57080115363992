// import { baseUrl } from "./../../config.json";
/* export const baseUrl =
  process.env.NODE_ENV_SETTINGS === "production"
    ? "https://online-service-booking.renault.ua/renault/api"
    : "https://rcs.dev.perx.ru/renault/api"; */

let baseUrl = window._env_.REACT_APP_API_HOST;

// auth 
export const getTokenUrl = `${baseUrl}/v1/auth/token/create`

// step 0
export const carInfoUrl = `${baseUrl}/carinfo`;
export const allCarsByVin = `${baseUrl}/allCars`;
export const carInfoByVinUrl = `${baseUrl}/bvm/carinfo`;

export const modelsUrl = `${baseUrl}/v1/models`;
export const yearsUrl = `${baseUrl}/v1/years`;
export const fuelsUrl = `${baseUrl}/v1/fuels`;
export const enginesUrl = `${baseUrl}/v1/engines`;
export const versionsUrl = `${baseUrl}/v1/versions`;
export const mileageUrl = `${baseUrl}/v1/mileage`;

// step 1
export const servicesUrl = `${baseUrl}/v1/calculations`;
export const categoriesUrl = `${baseUrl}/v1/categories`;
/* export const servicesUrl = `${baseUrl}/api/services`;
export const servicesBvmUrl = `${baseUrl}/api/servicesBvm`; */
export const otsUrl = `${baseUrl}/otspresent`;
