import { CHANGE_STEP_VALIDATION_STATE } from "../actions/validation";
import { SERVICE_BOOKING_STEPS } from "../constants";

const initialState = {
  stepsValidation: SERVICE_BOOKING_STEPS.map((step) => !step.isRequired),
};

export default function validation(state = initialState, action) {
  switch (action.type) {
    case CHANGE_STEP_VALIDATION_STATE:
      return {
        ...state,
        stepsValidation: [...state.stepsValidation].map((item, index) =>
          index === action.step.index ? action.step.state : item
        ),
      };

    default:
      return state;
  }
}
