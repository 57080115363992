import actionCreator from "./actionCreator";

export const INIT_STEP = "INIT_STEP";
export const REMOVE_STEP_INITION = "REMOVE_STEP_INITION";

export const initStep = (index) => actionCreator(INIT_STEP, { index });
export const removeStepInition = (index) =>
  actionCreator(REMOVE_STEP_INITION, { index });

export const requestStepInition = (index) => (dispatch, getState) => {
  const {
    init: { stepsInit },
  } = getState();
  if (stepsInit[index]) return;
  dispatch(initStep(index));
};
