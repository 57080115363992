import actionCreator from "./actionCreator";
import { getApi, postApi, getApiRaw, postApiRaw } from "../api/requests";
import { prevStep, requestNavigationStep } from "./navigation";
import { removeStepInition } from "./init";
import {
  timeslotBooked as bookedModal,
  timeout as timeoutModal,
  noDealers as noDealersModal,
} from "../Modal/modals";
import { getUrlParams } from "../helpers";
import { REQUEST_TIMEOUT } from "../constants";

import {
  carInfoUrl,
  otsUrl,
  servicesUrl,
  carInfoByVinUrl,
  allCarsByVin,
  modelsUrl,
  yearsUrl,
  enginesUrl,
  fuelsUrl,
  versionsUrl,
  mileageUrl,
  categoriesUrl,
  getTokenUrl,
} from "../api/endpoints";

const dealerBir = getUrlParams().bir;
const env = getUrlParams().env;

export const WRITE_VEHICLE_PARAMS = "WRITE_VEHICLE_PARAMS";
export const WRITE_MODELS_LIST = "WRITE_MODELS_LIST";

export const WRITE_MODEL_PARAMS = "WRITE_MODEL_PARAMS";
export const WRITE_SERVICES = "WRITE_SERVICES";
export const WRITE_DEALER = "WRITE_DEALER";
export const WRITE_DEALERS = "WRITE_DEALERS";
export const WRITE_TIMESLOTS = "WRITE_TIMESLOTS";
export const WRITE_SUBMIT_RESPONSE = "WRITE_SUBMIT_RESPONSE";
export const WRITE_CONFIRM_CANCEL_DATA = "WRITE_CONFIRM_CANCEL_DATA";
export const WRITE_SUBMIT_ID = "WRITE_SUBMIT_ID";
export const WRITE_CITIES_LIST = "WRITE_CITIES_LIST";

export const CHANGE_CITY_STATE = "CHANGE_CITY_STATE";
export const CHANGE_VEHICLE_STATE = "CHANGE_VEHICLE_STATE";
export const CHANGE_FETCH_STATE = "CHANGE_FETCH_STATE";
export const CHANGE_DEALER_STATE = "CHANGE_DEALER_STATE";

export const DISABLE_TIMESLOT = "DISABLE_TIMESLOT";
export const REMOVE_TIMESLOTS = "REMOVE_TIMESLOTS";
export const REMOVE_SERVICES = "REMOVE_SERVICES";

export const WRITE_MODELS = "WRITE_MODELS";
export const WRITE_YEARS = "WRITE_YEARS";
export const WRITE_FUELS = "WRITE_FUELS";
export const WRITE_ENGINES = "WRITE_ENGINES";
export const WRITE_VERSIONS = "WRITE_VERSIONS";
export const WRITE_MILEAGES = "WRITE_MILEAGES";
// STORE WRITING ACTIONS

export const writeVehicleParams = (vehicle) =>
  actionCreator(WRITE_VEHICLE_PARAMS, { vehicle });
export const writeServices = (services) =>
  actionCreator(WRITE_SERVICES, { services });
export const writeDealer = (dealer) => actionCreator(WRITE_DEALER, { dealer });
export const writeModelParams = (model) =>
  actionCreator(WRITE_MODEL_PARAMS, { model });
export const writeModelsList = (models) =>
  actionCreator(WRITE_MODELS_LIST, { models });
export const writeDealers = (dealers) =>
  actionCreator(WRITE_DEALERS, { dealers });
export const writeTimeslots = (timeslots, month) =>
  actionCreator(WRITE_TIMESLOTS, { timeslots, month });
export const writeSubmitResponse = (data) =>
  actionCreator(WRITE_SUBMIT_RESPONSE, { data });
export const writeConfirmCancelData = (data) =>
  actionCreator(WRITE_CONFIRM_CANCEL_DATA, { data });
export const writeSubmitId = (id) => actionCreator(WRITE_SUBMIT_ID, { id });
export const writeCitiesList = (cities) =>
  actionCreator(WRITE_CITIES_LIST, { cities });

export const changeCityState = (state) =>
  actionCreator(CHANGE_CITY_STATE, { state });
export const changeVehicleState = (state) =>
  actionCreator(CHANGE_VEHICLE_STATE, { state });
export const changeDealerState = (state) =>
  actionCreator(CHANGE_DEALER_STATE, { state });
export const changeFetchState = (state) =>
  actionCreator(CHANGE_FETCH_STATE, { state });

export const writeModels = (models) => actionCreator(WRITE_MODELS, { models });
export const writeYears = (years) => actionCreator(WRITE_YEARS, { years });
export const writeFuels = (fuels) => actionCreator(WRITE_FUELS, { fuels });
export const writeEngines = (engines) =>
  actionCreator(WRITE_ENGINES, { engines });
export const writeVersions = (versions) =>
  actionCreator(WRITE_VERSIONS, { versions });
export const writeMileages = (mileages) =>
  actionCreator(WRITE_MILEAGES, { mileages });

export const disableTimeslot = (id) => actionCreator(DISABLE_TIMESLOT, { id });
export const removeTimeslots = () => actionCreator(REMOVE_TIMESLOTS);
export const removeServices = () => actionCreator(REMOVE_SERVICES);

// STORE WRITING REQUESTS

// fetch state middleware

export const requestFetchStateChange = (state) => (dispatch, getState) => {
  const {
    requests: { isFetching },
  } = getState();
  if (isFetching === state) return;
  dispatch(changeFetchState(state));
};

const requestMiddleware = (dispatch, request, modal = timeoutModal) => {
  dispatch(requestFetchStateChange(true));

  // set request timeout
  const timer = setTimeout(() => {
    modal();
    dispatch(requestFetchStateChange(false));
  }, REQUEST_TIMEOUT);

  return request.then((r) => {
    clearTimeout(timer);
    dispatch(requestFetchStateChange(false));
    return r;
  });
};

// Vehicle parameters

export const requestVehicleByVin = (vin) => (dispatch) => {
  const req = getApiRaw(`${carInfoByVinUrl}/${vin}`)
    .then((res) => {
      if (res.ok && res.status >= 200 && res.status <= 299) {
        const response = res.json();
        response.then((vehicle) => dispatch(writeVehicleParams(vehicle)));
        return response;
      }
      if (res.status >= 400 && res.status <= 499) {
        return "vinError";
      }
      if (res.status >= 500 && res.status <= 599) {
        return null;
      }
      return null;
    })
    .catch(() => null);

  return requestMiddleware(dispatch, req, () => null);
};

export const requestOtsState = (vin) => (dispatch) =>
  getApi(`${otsUrl}?vin=${vin}`).then((state) =>
    state && state.vin
      ? dispatch(writeVehicleParams({ otspresent: state.otspresent }))
      : null
  );

export const requestChangeVehicleState = (state) => (dispatch, getState) => {
  const {
    requests: { vehicleChanged },
  } = getState();
  if (vehicleChanged === state) return;
  if (state === true) dispatch(removeServices());
  dispatch(changeVehicleState(state));
};

// services

export const requestCategories = () => (dispatch, getState) => {
  const {
    step0: { vehicleParams },
  } = getState();
  const req = getApi(
    `${categoriesUrl}?versionId=${vehicleParams.versionId}`
  ).then((serviceTypes) => {
    // serviceTypes ? dispatch(writeServiceTypes(serviceTypes)) : null
    if (serviceTypes) {
      dispatch(writeServices(serviceTypes));
      dispatch(requestChangeVehicleState(false));
      return serviceTypes;
    } else {
      return [];
    }
  });

  return requestMiddleware(dispatch, req);
};

export const requestServices = (categoryId) => async (dispatch, getState) => {
  const {
    requests: { services, vehicleChanged },
    // step3: { chosenDealerID },
    step0: { vehicleParams },
  } = getState();

  const requestParams = {
    ...(vehicleParams.vin
      ? { type: "vin", vin: vehicleParams.vin }
      : {
          type: "manual",
          version: vehicleParams.versionId,
          year: vehicleParams.year,
          abs: vehicleParams.abs,
          conditioner: vehicleParams.conditioner,
          powerSteering: vehicleParams.pas,
        }),
    mileage: vehicleParams.mileage,
    bir: dealerBir,
    laborType: categoryId,
  };

  const req = postApi(servicesUrl, requestParams).then((services) => {
    const servicesList = services.result;
    if (servicesList) {
      dispatch(writeServices(servicesList));
      dispatch(writeDealer(services.dealer));
      dispatch(requestChangeVehicleState(false));
      if (!!requestParams.mileage && !!requestParams.bir) {
        return servicesList;
      } else {
        return [];
      }
    }
    return [];
  });
  return requestMiddleware(dispatch, req);
};

export const requestSubmit = (data) => async (dispatch, getState) => {
  const {
    requests: {
      services,
      dealer: { abbr },
    },
    step1: { chosenServices },
  } = getState();
  const chosenServicesArray = [];
  for (const prop in chosenServices) {
    const serviceGroup = services.find((service) => service.group === prop);
    const resultGroup = {
      group: serviceGroup.group,
      serviceTypeId: serviceGroup.serviceTypeId,
      services: [],
    };
    if (Object.prototype.hasOwnProperty.call(chosenServices, prop)) {
      if (!!chosenServices[prop].length) {
        chosenServices[prop].forEach((chosenService) => {
          resultGroup.services.push(
            serviceGroup.services.find(
              (service) => service.name === chosenService.name
            )
          );
        });
      }
    }
    resultGroup.services.length && chosenServicesArray.push(resultGroup);
  }
  const servicesString = JSON.stringify(chosenServicesArray);
  const dmsUrl = `https://${env === 'demo' ? 'demo-' : ''}${abbr.toLowerCase()}.dms.renault.ua/${env === 'demo' ? 'demo-' : ''}${abbr.toLowerCase()}`;
  console.log(servicesString);
  window.parent.postMessage(servicesString, dmsUrl);
};

// Models

export const requestModelsList = (isByVin) => (dispatch) => {
  const req = getApi(!!isByVin ? allCarsByVin : carInfoUrl).then((models) =>
    models ? dispatch(writeModelsList(models)) : null
  );
  return requestMiddleware(dispatch, req);
};

export const requestToken = (apiKey) => (dispatch) => {
  const req = getApi(`${getTokenUrl}?apiKey=${apiKey}`).then((response) => {
    sessionStorage.setItem("accessToken", response.token);
  });
  return requestMiddleware(dispatch, req);
};

export const requestModels = () => (dispatch) => {
  const req = getApi(modelsUrl).then((models) =>
    models ? dispatch(writeModels(models)) : null
  );
  return requestMiddleware(dispatch, req);
};

export const requestYears = (model) => (dispatch) => {
  const req = getApi(`${yearsUrl}?model=${model}`).then((years) =>
    years ? dispatch(writeYears(years)) : null
  );
  return requestMiddleware(dispatch, req);
};

export const requestFuels = (model, year) => (dispatch) => {
  const req = getApi(`${fuelsUrl}?model=${model}&year=${year}`).then((fuels) =>
    fuels ? dispatch(writeFuels(fuels)) : null
  );
  return requestMiddleware(dispatch, req);
};

export const requestEngines = (model, year, fuel) => (dispatch) => {
  const req = getApi(
    `${enginesUrl}?model=${model}&year=${year}&fuel=${fuel}`
  ).then((engines) => (engines ? dispatch(writeEngines(engines)) : null));
  return requestMiddleware(dispatch, req);
};

export const requestVersions = (model, year, fuel, engine) => (dispatch) => {
  const req = getApi(
    `${versionsUrl}?model=${model}&year=${year}&fuel=${fuel}&engine=${engine}`
  ).then((versions) => (versions ? dispatch(writeVersions(versions)) : null));
  return requestMiddleware(dispatch, req);
};

export const requestMileage = (version) => (dispatch) => {
  const req = getApi(`${mileageUrl}?version=${version}`).then((mileages) =>
    mileages ? dispatch(writeMileages(mileages)) : null
  );
  return requestMiddleware(dispatch, req);
};
