import { renderModal } from "./";

// common creator for modals
const modalCreator = (params, actionFunc, okFunc) => {
  const options = Object.assign(
    {},
    { ...params },
    actionFunc ? { action: actionFunc } : null,
    okFunc ? { okAction: okFunc } : null
  );

  return renderModal(options);
};

export const agreement = (actionFunc, okFunc) => {
  const options = {
    heading: "Рассчитайте стоимость технического обслуживания",
    content:
      "Быстрый и удобный выбор необходимых услуг по\u00a0программе Комплекс-Сервис, в\u00a0которую мы уже включили стоимость работ, запчастей и\u00a0расходных материалов.",
    okText: "Рассчитать",
    actionText: "Пропустить"
  };

  return modalCreator(options, actionFunc, okFunc);
};

export const maxServices = (actionFunc, okFunc) => {
  const options = {
    heading: "Выбрано максимальное количество комплекс-сервисов",
    content:
      "Вы выбрали 3\u00a0услуги\u00a0 — максимальное количество, доступное для онлайн-записи. Вы\u00a0можете изменить свой выбор или перейти на\u00a0следующий шаг и\u00a0продолжить запись.",
    okText: "Изменить выбор",
    actionText: "Продолжить"
  };

  return modalCreator(options, actionFunc, okFunc);
};

export const timeslotBooked = (actionFunc, okFunc) => {
  const options = {
    heading: "Схоже, на цей час вже хтось записався",
    content: "Вам необхідно вибрати інший час.",
    okText: "Обрати"
  };

  return modalCreator(options, actionFunc, okFunc);
};

export const timeout = () => {
  const options = {
    heading: "Сервіс тимчасово недоступний",
    content:
      "Вибачте, в даний час проводяться технічні роботи. Будь ласка, спробуйте пізніше."
  };
  return modalCreator(options);
};

export const noServices = (actionFunc, okFunc) => {
  const options = {
    content:
      'На жаль, для Вашого автомобіля нам не вдалось підібрати пропозицю, що входить в програму "Базового ТО". Вартість робіт та запчастин Ви зможете дізнатись, звернувшись безпосередньо до дилера. Для продовження натисніть на кнопку "Запис"',
    okText: "Запис"
  };
  return modalCreator(options, actionFunc, okFunc);
};

export const geolocationFail = errorType => {
  const deniedGeo =
    "У вас встановлена заборона на визначення місця розташування. Змініть це в настройках браузера і спробуйте ще раз.";
  const otherError = "Виникла помилка. Будь ласка, спробуйте ще раз пізніше.";
  const options = {
    heading: "Неможливо визначити местоложеніе",
    content: errorType === 1 ? deniedGeo : otherError
  };
  return modalCreator(options);
};

export const noDealers = (actionFunc, okFunc) => {
  const options = {
    heading: "Дилерів в даному місті не знайдено",
    content:
      "На жаль, в обраному місті не знайдено жодного дилера, будь ласка, виберіть інший найближчий місто зі списку.",
    okText: "Обрати"
  };
  return modalCreator(options, actionFunc, okFunc);
};

export const noSlots = (actionFunc, okFunc, cancelBtn) => {
  const options = {
    content:
      "На жаль, в даному дилерських центрів, немає вільних слотів для онлайн записи на сайті. Будь ласка, зверніться до дилера, щоб записатися на сервіс.",
    actionText: "Записатись"
  };
  if (cancelBtn) options["okText"] = "Вибрати іншого дилера";
  return modalCreator(options, actionFunc, okFunc);
};

export const noBackend = okFunc => {
  const options = {
    content:
      "Вибачте, в даний час підбір по VIN недоступний. Будь ласка, підберіть характеристики моделі вручну.",
    okText: "Обрати"
  };
  return modalCreator(options, null, okFunc);
};

export const submitError = () => {
  const options = {
    content:
      "Вибачте, в даний час проводяться технічні роботи. Будь ласка, спробуйте записатися пізніше."
  };
  return modalCreator(options);
};

export const vinParseError = okFunc => {
  const options = {
    heading: "Неможливо визначити автомобіль по VIN",
    content: "Будь ласка, виберіть характеристики моделі вручну",
    okText: "Обрати"
  };
  return modalCreator(options, null, okFunc);
};
