import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router";
import scrollToComponent from "react-scroll-to-component";

import asyncComponent from "./Async";

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.scrolToTop = this.scrollToTop.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) this.scrollToTop();
  }

  scrollToTop() {
    // scroll parent window if widget in iframe
    if (window !== window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          type: "scrolltopiframe",
        }),
        "https://www.renault.ua/"
      );
      return;
    }

    if (this.app) {
      scrollToComponent(this.app, {
        offset: 0,
        align: "top",
        duration: 500,
        ease: "inOutQuart",
      });
    }
  }

  render() {
    return (
      <Switch
        ref={(app) => {
          this.app = app;
        }}
      >
        <Route
          path="/step:routeStepIndex"
          render={(props) => {
            const Async = asyncComponent(() => import("./Container"));
            return <Async {...props} />;
          }}
        />
        <Redirect exact from="/" to="/step0" />
      </Switch>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default App;
