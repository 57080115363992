import history from "../history";
import actionCreator from "./actionCreator";
import { SERVICE_BOOKING_STEPS } from "../constants";
import { requestStepInition } from "./init";
// import { requestFetchStateChange } from "./requests";

export const REQUEST_NEXT_STEP = "REQUEST_NEXT_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const PREV_STEP = "PREV_STEP";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const FORM_SUBMITTED_STATE = "FORM_SUBMITTED_STATE";

function createCustomEvent(name) {
  const event = document.createEvent("Event");
  event.initEvent(name, true, true);
  document.dispatchEvent(event);
}

export const setActiveStep = (index) =>
  actionCreator(SET_ACTIVE_STEP, { index });
export const formSubmittedState = () => actionCreator(FORM_SUBMITTED_STATE);

export const nextStep = () => (dispatch, getState) => {
  const {
    navigation: { activeStepIndex },
  } = getState();
  if (activeStepIndex === SERVICE_BOOKING_STEPS.length - 1) return;

  history.push(`/step${activeStepIndex + 1}`);
};

export const prevStep = () => (dispatch, getState) => {
  const {
    navigation: { activeStepIndex },
  } = getState();
  if (activeStepIndex === 0) return;
  history.push(`/step${activeStepIndex - 1}`);
};

export const requestNextStep = () => (dispatch, getState) => {
  const {
    validation: { stepsValidation },
    navigation: { activeStepIndex },
  } = getState();
  if (!stepsValidation[activeStepIndex]) {
    createCustomEvent("validateStep");
    return;
  }
  dispatch(nextStep(history));
};

export const requestActiveStep = (index) => (dispatch, getState) => {
  const {
    validation: { stepsValidation },
    navigation: { submitted },
  } = getState();

  if (submitted) {
    history.push("/confirm");
    return;
  }

  if (index < 0) history.push("/step0");

  const prevSteps = stepsValidation.slice(0, index);
  if (
    index > SERVICE_BOOKING_STEPS.length ||
    (index > 0 && !prevSteps.every((step) => step))
  ) {
    const firstNotValidatedStep = stepsValidation.indexOf(false);
    history.push(`/step${firstNotValidatedStep}`);
    return;
  }
  dispatch(setActiveStep(index));
  // dispatch(requestFetchStateChange(false));
  dispatch(requestStepInition(index));
};

export const requestNavigationStep = (index) => (dispatch, getState) => {
  const {
    init: { stepsInit },
    navigation: { activeStepIndex },
    validation: { stepsValidation },
  } = getState();

  if (
    (index > activeStepIndex && !stepsValidation[activeStepIndex]) ||
    index === activeStepIndex ||
    !stepsInit[index]
  )
    return;

  history.push(`step${index}`);
};

export const requestConfirmPage = () => (dispatch, getState) => {
  const {
    init: { stepsInit },
    navigation: { submitted },
  } = getState();

  if (!stepsInit.every((init) => !!init) && !submitted) {
    history.push("/");
    return;
  }

  if (!submitted) dispatch(formSubmittedState());
  if (history.location.pathname !== "/confirm") history.push("/confirm");
};
