import {
  ADD_SERVICE,
  DELETE_SERVICE,
  INIT_SERVICES,
  MODAL_SHOWN,
} from "../actions/step1";
import { REMOVE_SERVICES } from "../actions/requests";

const initialState = {
  chosenServices: {},
  modalShown: false,
};

export default function services(state = initialState, action) {
  switch (action.type) {
    case INIT_SERVICES:
      return {
        ...state,
        chosenServices: action.services.reduce(
          (acc, item) => ({ ...acc, [item.group]: [] }),
          {}
        ),
      };

    case ADD_SERVICE:
      return {
        ...state,
        chosenServices: {
          ...state.chosenServices,
          [action.group]: [
            ...state.chosenServices[action.group],
            {
              name: action.name,
              prices: action.prices,
              jobs: action.jobs,
              parts: action.parts,
              stockPrice: action.stockPrice,
            },
          ],
        },
      };

    case DELETE_SERVICE:
      return {
        ...state,
        chosenServices: {
          ...state.chosenServices,
          [action.group]: state.chosenServices[action.group].filter(
            (service) => service.name !== action.name
          ),
        },
      };

    case REMOVE_SERVICES:
      return {
        ...state,
        chosenServices: {},
      };

    case MODAL_SHOWN:
      return {
        ...state,
        modalShown: true,
      };

    default:
      return state;
  }
}
