import "whatwg-fetch";
import { getTokenUrl } from "./endpoints";
import { getUrlParams } from "../helpers";

const { apiKey } = getUrlParams();

export const callApi = (
  url,
  method = "GET",
  data = {},
  rawResponse = false
) => {
  const fetchData = Object.assign(
    {},
    {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    },
    method !== "GET" ? { body: JSON.stringify(data) } : null
  );

  // get raw error data if needed
  if (rawResponse) return fetch(encodeURI(url), fetchData);

  return fetch(encodeURI(url), fetchData).then((response) => {
    if (response.status >= 200 && response.status <= 299) {
      const list = response.json();
      return list;
    }
    if (response.status === 403 || response.status === 401) {
      return fetch(`${getTokenUrl}?apiKey=${apiKey}`, fetchData).then(
        (response) => {
          return response.json().then((json) => {
            sessionStorage.setItem("accessToken", json.token);
            const newFetchData = Object.assign(
              {},
              {
                method,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${sessionStorage.getItem(
                    "accessToken"
                  )}`,
                },
              },
              method !== "GET" ? { body: JSON.stringify(data) } : null
            );
            return fetch(encodeURI(url), newFetchData).then((response) => {
              if (response.status >= 200 && response.status <= 299) {
                const list = response.json();
                return list;
              }
              return null;
            });
          });
        }
      );
    }

    return null;
  });
};

export const getApi = (url) => callApi(url);
export const getApiRaw = (url) => callApi(url, "GET", {}, true);
export const postApi = (url, data) => callApi(url, "POST", data);
export const postApiRaw = (url, data) => callApi(url, "POST", data, true);
