import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createHashHistory } from "history";
import { Router, Route } from "react-router";

import reducers from "./reducers";
import App from "./App";

import "./static/base.css";
import "./static/fonts.css";
import "./static/layout.css";
import "./static/animation.css";
import "./static/googleSearch.css";

const middlewares = [thunk];

if (process.env.NODE_ENV === "development" || !process.env.NODE_ENV) {
  const { logger } = require("redux-logger"); // eslint-disable-line global-require
  middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));
const history = createHashHistory();

render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={App} />
    </Router>
  </Provider>,
  document.querySelector("#root")
);
