import { INIT_STEP, REMOVE_STEP_INITION } from "../actions/init";
import { SERVICE_BOOKING_STEPS } from "../constants";

const initialState = {
  stepsInit: SERVICE_BOOKING_STEPS.map(() => false),
};

function changeArrayValue(array, index, value) {
  return [...array].map((item, i) => (i === index ? value : item));
}

function changeArrayValuesAfter(array, index, value) {
  return [...array].map((item, i) => (i >= index ? value : item));
}

export default function initialization(state = initialState, action) {
  switch (action.type) {
    case INIT_STEP:
      return {
        ...state,
        stepsInit: changeArrayValue(state.stepsInit, action.index, true),
      };

    case REMOVE_STEP_INITION:
      return {
        ...state,
        stepsInit: changeArrayValuesAfter(state.stepsInit, action.index, false),
      };

    default:
      return state;
  }
}
