import React, { PureComponent } from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";
import Button from "../Components/Button";
import Wrapper from "./Wrapper";

import styles from "./styles.css";

function empty() {
  return false;
}

class Modal extends PureComponent {
  render() {
    const {
      heading,
      content,
      actionText,
      okText,
      action,
      okAction
    } = this.props;
    return (
      <Wrapper>
        <div className={`${styles.modalContainer} closemodal`}>
          <div className={styles.modalPosition}>
            <div className={styles.modal}>
              {heading && <h1>{heading}</h1>}
              {content && <p>{content}</p>}
              <div className={styles.buttonsBox}>
                <Button onClick={okAction} classes="closemodal">
                  {okText}
                </Button>
                {(action !== empty || actionText) && (
                  <Button onClick={action} grey classes="closemodal">
                    {actionText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export function renderModal(options) {
  render(<Modal {...options} />, document.getElementById("app-modals"));
}

Modal.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  action: PropTypes.func,
  actionText: PropTypes.string,
  okText: PropTypes.string,
  okAction: PropTypes.func
};

Modal.defaultProps = {
  heading: "",
  content: "",
  action: empty,
  actionText: "",
  okText: "ОК",
  okAction: empty
};

export default Modal;
