import actionCreator from "./actionCreator";

export const INIT_SERVICES = "INIT_SERVICES";
export const ADD_SERVICE = "ADD_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const MODAL_SHOWN = "MODAL_SHOWN";

export const initServices = (services) =>
  actionCreator(INIT_SERVICES, { services });
export const modalShownState = () => actionCreator(MODAL_SHOWN);
export const addService = (group, name, prices, jobs, parts, stockPrice) =>
  actionCreator(ADD_SERVICE, {
    group,
    name,
    prices,
    jobs,
    parts,
    stockPrice,
  });

export const deleteService = (group, name) =>
  actionCreator(DELETE_SERVICE, { group, name });

export const requestModalShown = () => (dispatch, getState) => {
  const {
    step1: { modalShown },
  } = getState();
  if (modalShown) return;
  dispatch(modalShownState());
};
