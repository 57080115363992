import { ADD_CAR_PARAMETERS } from "../actions/step0";

const initialState = {
  vehicleParams: {
    isByVin: true,
    vin: "",
    mileage: "",
    model: "",
    version: "",
    versionName: {},
    bvmVersion: {},
    engine: "",
    fuel: "",
    year: "",
    versionId: "",
    conditioner: false,
    abs: false,
    pas: false,
    mileages: [],
  },
};

export default function step1(state = initialState, action) {
  switch (action.type) {
    case ADD_CAR_PARAMETERS:
      return {
        ...state,
        vehicleParams: {
          ...state.vehicleParams,
          ...action.params,
        },
      };

    default:
      return state;
  }
}
