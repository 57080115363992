import React, { Component } from "react";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }

    async componentWillMount() {
      const { default: component } = await importComponent();
      this.setState({ component });
    }

    componentWillUpdate(nextProps, nextState) {
      // remove inline height and add position for app container
      if (!this.state.component && nextState.component) {
        const appContainer = document.querySelector("#root");
        appContainer.style.height = "";
        appContainer.style.position = "relative";
      }
    }

    componentWillUnmount() {
      this.setState({ component: null });
    }

    render() {
      const Comp = this.state.component;
      return Comp ? <Comp {...this.props} /> : null;
    }
  }
  return AsyncComponent;
}
