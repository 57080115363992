import actionCreator from "./actionCreator";

export const CHANGE_STEP_VALIDATION_STATE = "CHANGE_STEP_VALIDATION_STATE";

export const changeStepValidationState = (step) =>
  actionCreator(CHANGE_STEP_VALIDATION_STATE, { step });

// step = { state: bool, index: number }
export const requestStepValidation = (step) => (dispatch, getState) => {
  const {
    validation: { stepsValidation },
  } = getState();
  if (step.state === stepsValidation[step.index]) return;
  dispatch(changeStepValidationState(step));
};
