import { combineReducers } from "redux";
import navigation from "./navigation";
import step0 from "./step0";
import step1 from "./step1";
/* import step2 from "./step2";
import step3 from "./step3";
import step4 from "./step4";
import step5 from "./step5";*/
import validation from "./validation";
import init from "./init";
import requests from "./requests";

export default combineReducers({
  navigation,
  step0,
  step1,
  validation,
  init,
  requests,
});
