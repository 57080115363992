const urlParams = new URLSearchParams(window.location.search);
const env = urlParams.get('env');
const vin = urlParams.get("vin");
const bir = urlParams.get("bir");
const model = urlParams.get("model");
const apiKey = urlParams.get("apiKey");

export const getUrlParams = () => {
  return { bir, vin, model, apiKey, env };
};
