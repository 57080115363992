import {
  WRITE_VEHICLE_PARAMS,
  WRITE_SERVICES,
  WRITE_MODEL_PARAMS,
  WRITE_MODELS_LIST,
  WRITE_CITIES_LIST,
  WRITE_DEALERS,
  WRITE_TIMESLOTS,
  WRITE_SUBMIT_RESPONSE,
  WRITE_CONFIRM_CANCEL_DATA,
  WRITE_SUBMIT_ID,
  CHANGE_CITY_STATE,
  CHANGE_FETCH_STATE,
  CHANGE_DEALER_STATE,
  CHANGE_VEHICLE_STATE,
  DISABLE_TIMESLOT,
  REMOVE_TIMESLOTS,
  REMOVE_SERVICES,
  WRITE_MODELS,
  WRITE_YEARS,
  WRITE_FUELS,
  WRITE_ENGINES,
  WRITE_VERSIONS,
  WRITE_MILEAGES,
  WRITE_DEALER,
} from "../actions/requests";

const initialState = {
  city: "moscow",
  vehicle: {
    isByVin: true,
    vin: "",
    model: "",
    version: "",
    engine: "",
    fuel: "",
    conditioner: false,
    filter: false,
    abs: false,
    pas: false,
    year: "",
  },
  services: [],
  vehicleChanged: false,
  cityChanged: true,
  model: {},
  modelsList: {},
  dealers: [],
  dealerChanged: true,
  timeslots: {},
  submitInfo: {},
  dataConfirmCancel: {},
  isFetching: false,
  cities: [],
  models: [],
  years: [],
  fuels: [],
  engines: [],
  versions: [],
  mileages: [],
};

export default function requests(state = initialState, action) {
  switch (action.type) {
    case WRITE_VEHICLE_PARAMS: {
      const { model, version, engine, ...restParams } = action.vehicle;
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          isByVin: true,
          model: model || "novalue",
          version: version || "novalue",
          engine: engine || "novalue",
          ...restParams,
        },
      };
    }

    case WRITE_SERVICES:
      let { services } = state;
      if (services.length >= 1) {
        services = services.map((service) => {
          if (service.group === action.services[0].group) {
            service.services = action.services[0].services;
          }
          return service;
        });
      }
      return {
        ...state,
        services: services.length >= 1 ? services : action.services,
      };

    case WRITE_DEALER:
      return {
        ...state,
        dealer: action.dealer,
      };

    case WRITE_MODEL_PARAMS:
      return {
        ...state,
        model: action.model[0],
      };

    case WRITE_MODELS_LIST:
      return {
        ...state,
        modelsList: action.models,
      };

    case WRITE_MODELS:
      return {
        ...state,
        models: action.models,
      };

    case WRITE_YEARS:
      return {
        ...state,
        years: action.years,
      };

    case WRITE_FUELS:
      return {
        ...state,
        fuels: action.fuels,
      };

    case WRITE_ENGINES:
      return {
        ...state,
        engines: action.engines,
      };

    case WRITE_VERSIONS:
      return {
        ...state,
        versions: action.versions,
      };

    case WRITE_MILEAGES:
      return {
        ...state,
        mileages: action.mileages,
      };

    case WRITE_DEALERS:
      return {
        ...state,
        dealers: action.dealers,
      };

    case CHANGE_CITY_STATE:
      return {
        ...state,
        cityChanged: action.state,
      };

    case CHANGE_DEALER_STATE:
      return {
        ...state,
        dealerChanged: action.state,
      };

    case WRITE_TIMESLOTS:
      return {
        ...state,
        timeslots: {
          ...state.timeslots,
          [action.month]: [...action.timeslots],
        },
        // support for disabled state
        /* ...state,
        timeslots: action.timeslots.map(i => ({
          ...i,
          timeslots: i.timeslots.map(timeslot =>
            ({
              ...timeslot,
              disabled: false,
            })),
        })),
        */
      };

    case DISABLE_TIMESLOT:
      return {
        ...state,
        timeslots: state.timeslots.map((i) => ({
          ...i,
          timeslots: i.timeslots.map((timeslot) =>
            timeslot.id === action.id
              ? {
                  ...timeslot,
                  disabled: true,
                }
              : timeslot
          ),
        })),
      };

    case REMOVE_TIMESLOTS:
      return {
        ...state,
        timeslots: {},
      };

    case WRITE_SUBMIT_RESPONSE:
      return {
        ...state,
        submitInfo: action.data,
      };

    case WRITE_CONFIRM_CANCEL_DATA:
      return {
        ...state,
        dataConfirmCancel: action.data,
      };

    case WRITE_SUBMIT_ID:
      return {
        ...state,
        submitInfo: {
          ...state.submitInfo,
          id: action.id,
        },
      };

    case CHANGE_FETCH_STATE:
      return {
        ...state,
        isFetching: action.state,
      };

    case WRITE_CITIES_LIST:
      return {
        ...state,
        cities: action.cities.reduce(
          (acc, city) => [...acc, { id: city[0], value: city[1] }],
          []
        ),
      };

    case CHANGE_VEHICLE_STATE:
      return {
        ...state,
        vehicleChanged: action.state,
      };

    case REMOVE_SERVICES:
      return {
        ...state,
        services: [],
      };

    default:
      return state;
  }
}
